import { Mixpanel } from './mixPanel.js';
import { analyticsConstant } from '../Constants/analyticsConstants';
import { getMedicineName, getServiceName } from '../Services/common.js';

const trackEvent = (eventName, data) => {
    Mixpanel.track(eventName, { ...data });
};



export const setUser = (data) => {
    Mixpanel.identify(data.email)
    Mixpanel.people.set({
        "id": data?.id,
        $name: data?.name,
        role: data?.role,
        $email: data?.email
    });
}


export const trackPatientVisit = (hospitalName, hospitalId, patientId, patientVisitId) => {
    trackEvent(analyticsConstant.PATIENT_VISIT, {
        'Hospital Name': hospitalName,
        'Hospital Id': hospitalId,
        'Patient Id': patientId,
        'Patient Visit Id': patientVisitId
    });
};

export const trackMedicineSale = (hospitalName, hospitalId, medicines) => {
    medicines.forEach(medicine => {
        trackEvent(analyticsConstant.MEDICINE_SALE, {
            'Hospital Name': hospitalName,
            'Hospital Id': hospitalId,
            'Medicine Id': medicine.medicine,
            'Medicine Name': getMedicineName(medicine.medicine),
            'Medicine Master Id': medicine.product_detail_id
        });
    });
};

export const trackServiceSale = (hospitalName, hospitalId, services) => {
    services.forEach(service => {
        trackEvent(analyticsConstant.SERVICE_SALE, {
            'Hospital Name': hospitalName,
            'Hospital Id': hospitalId,
            'Service Id': service.service,
            'Service Name': getServiceName(service.service),
            'Service Master Id': service.id
        });
    });
};

export const trackAadharSearch = (hospitalName, hospitalId) => {
    trackEvent(analyticsConstant.AADHAR_SEARCH, {
        'Hospital Name': hospitalName,
        'Hospital Id': hospitalId
    });
};

export const trackMobileNumberSearch = (hospitalName, hospitalId) => {
    trackEvent(analyticsConstant.MNO_SEARCH, {
        'Hospital Name': hospitalName,
        'Hospital Id': hospitalId
    });
};

export const trackFileNumberSearch = (hospitalName, hospitalId) => {
    trackEvent(analyticsConstant.FILE_SEARCH, {
        'Hospital Name': hospitalName,
        'Hospital Id': hospitalId
    });
};

export const trackOnlineIdSearch = (hospitalName, hospitalId) => {
    trackEvent(analyticsConstant.ONLINE_ID_SEARCH, {
        'Hospital Name': hospitalName,
        'Hospital Id': hospitalId
    });
};

export const trackWarning = (hospitalName, hospitalId, message) => {
    trackEvent(analyticsConstant.WARNING, {
        'Hospital Name': hospitalName,
        'Hospital Id': hospitalId,
        'msg': message
    });
};

export const trackPatientStatusFilter = (hospitalName, hospitalId, status) => {
    trackEvent(analyticsConstant.PATIENT_STATUS_FILTER, {
        'Hospital Name': hospitalName,
        'Hospital Id': hospitalId,
        'STATUS': status
    });
};

export const trackPatientSearch = (hospitalName, hospitalId) => {
    trackEvent(analyticsConstant.PATIENT_SEARCH, {
        'Hospital Name': hospitalName,
        'Hospital Id': hospitalId
    });
};

export const trackPatientDateFilter = (hospitalName, hospitalId) => {
    trackEvent(analyticsConstant.PATIENT_DATE_FILTER, {
        'Hospital Name': hospitalName,
        'Hospital Id': hospitalId
    });
};

export const trackSaleReturn = (hospitalName, hospitalId, saleReturnList) => {
    saleReturnList.forEach((sales) => {
        trackEvent(analyticsConstant.SALE_RETURN, {
            'Hospital Name': hospitalName,
            'Hospital Id': hospitalId,
            'Sold Medicine Name': getMedicineName(sales.medicine)
        });
    })
};

export const trackReportView = (hospitalName, hospitalId, reportName) => {
    trackEvent(analyticsConstant.REPORT_VIEW, {
        'Hospital Name': hospitalName,
        'Hospital Id': hospitalId,
        'Report Name': reportName
    });
};

export const trackReportDownload = (hospitalName, hospitalId, reportName) => {
    trackEvent(analyticsConstant.REPORT_DOWNLOAD, {
        'Hospital Name': hospitalName,
        'Hospital Id': hospitalId,
        'Report Name': reportName
    });
};

export const trackAPITime = (time, url, status) => {

    let baseUrl = url; // Default to the original URL in case of failure

    try {
        // Attempt to extract the base URL (without query parameters)
        baseUrl = url.includes('?') ? url.split('?')[0] : url;
    } catch (error) {
        // Log the error or handle it as needed
        console.error('Error extracting base URL:', error);
    }

    trackEvent(analyticsConstant.API_TIME, {
        'response_time_ms': time,
        'status': status,
        'fullUrl': url,
        'url': baseUrl
    });
};