export const MASTER_DATA = "master_data";
export const USER_DATA = "user_data";
export const SYSTEM_DATA = "system_data";
export const GUEST_USER = "guest";
export const AUTH = 'auth';
export const PERMISSIONS = 'p';
export const CONFIG = 'c';
export const INVESTIGATION = 'iv';
export const DOCTORS = 'doctors';
export const HOSPITAL_MOBILE = 'hospital_mno';
export const HOSPITAL_SERVICES = 'hospital_services';
export const DATES = 'dates';
export const PROFILE = 'profile';
export const SKILLS = 'skills';
export const USER_ROLE = 'r';
export const YEARS_TOTAL = 40;
export const APP_NAME = "TrueTalent";
export const APP_URL = "TrueTalent.io";
export const RUPEE = "₹";
export const LOCATION_THRESHOLD = 2;
export const SKILL_THRESHOLD = 1;
export const CANDIDATES_PER_PAGE_LIMIT = 48;
export const CURRENCY_FORMAT = "en-IN";
export const PREFIX_CANDIDATE_ID = "TT1000";
export const SESSION = "session";
export const LOCAL = "local";
export const COUNTRY = "IN";
export const APPLICANTS = "applicants";
export const LOCATIONS = 'locations';
export const BUDGET_LIMIT = 9999999;
export const BUDGET_LIMIT_TEXT = '99,99,999';
export const REMOTE = 'Remote';
export const PROFILE_NAME = 'profileName';
export const LOGIN_DATA = 'login_data';
export const PHOTO = 'photo';
export const HOSPITAL_NAME = 'hospital_name';
export const HOSPITAL_ADDRESS = 'hospital_address';
export const HOSPITAL_ID = 'hospital_id';
export const COLOR = 'color';
export const NO_RESULT_ON_FILTER = 'Based on your selected criteria of ';

